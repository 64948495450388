<template>
  <b-nav-item @click="chat = isOpen ? false : true">
    <feather-icon
      size="21"
      :icon="`${isOpen ? 'Send' : 'Mail'}Icon`"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
  setup() {
    const { chat } = useAppConfig()

    const isOpen = computed(() => chat.value === true)

    return { chat, isOpen }
  },
}
</script>
